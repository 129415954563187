<script setup>
import { defineAsyncComponent ,useAttrs} from 'vue';
const props = defineProps({
    icon: {
        type: String,
        required: true,
    }
});
const attrs = useAttrs();
const icon = defineAsyncComponent( () => import(`../../svg/${props.icon}.svg`))
</script>

<template>
    <component :is="icon" v-bind="attrs" :height="null" :width="null"/>
</template>

