import {createApp, h} from 'vue'
import {createInertiaApp} from '@inertiajs/vue3'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import Icon from "@/js/Components/Icon.vue";
import Toast from 'vue-toastification';
import "vue-toastification/dist/index.css";

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        createApp({render: () => h(App, props)})
            .component("Icon", Icon)
            .use(plugin)
            .use(Toast, {position: "top-left", timeout: 2500})
            .mount(el);
    },
});
